import {useState} from "react";

function GameSettings({ p, c, onSubmit }) {

    const [nb_players, setNbPlayers] = useState(p);
    const [nb_courts, setNbCourts] = useState(c);

    const handleSubmit = () => {
        onSubmit({ nb_players, nb_courts });
    };

    return (
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h1 className="text-4xl font-medium font-outfit mb-6 text-green-600">Bad'à'Cartes</h1>
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <label htmlFor="">
                    <span className="text-gray-700">Nombre de terrains</span>
                </label>
                <div className="mb-4 flex items-center justify-center">
                    <button
                        type="button"
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
                        onClick={() => setNbPlayers(nb_players - 1)}
                    >
                        -
                    </button>
                    <input
                        className="shadow appearance-none w-16 text-center py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="number"
                        value={nb_players}
                        onChange={(e) => setNbPlayers(Number(e.target.value))}
                    />
                    <button
                        type="button"
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
                        onClick={() => setNbPlayers(nb_players + 1)}
                    >
                        +
                    </button>
                </div>
                <label htmlFor="">
                    <span className="text-gray-700">Nombre de joueurs</span>
                </label>
                <div className="mb-6 flex items-center justify-center">
                    <button
                        type="button"
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
                        onClick={() => setNbCourts(nb_courts - 1)}
                    >
                        -
                    </button>
                    <input
                        className="shadow appearance-none w-16 text-center py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="number"
                        value={nb_courts}
                        onChange={(e) => setNbCourts(Number(e.target.value))}
                    />
                    <button
                        type="button"
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
                        onClick={() => setNbCourts(nb_courts + 1)}
                    >
                        +
                    </button>
                </div>
                <div className="flex items-center justify-between">
                    <button
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                    >
                        Entrer
                    </button>
                </div>
            </form>
        </div>
    );
}

export default GameSettings;