import React, {useEffect, useState} from 'react';
import {tournament_api} from "../../api/tournament_api";
import {useInput} from "../../hooks/input-hook";

function MatchDisplay ({match, players, courts, tournament_id}) {

    console.log(match);
    console.log(courts);

    const { value:court, bind:bindCourt, reset:resetCourt } = useInput(0);
    const { value:first_player_clicked, bind:bindFirstPlayerClicked, reset:resetFirstPlayerClicked } = useInput(false);
    const { value:second_player_clicked, bind:bindSecondPlayerClicked, reset:resetSecondPlayerClicked } = useInput(false);
    const { value:third_player_clicked, bind:bindThirdPlayerClicked, reset:resetThirdPlayerClicked } = useInput(false);
    const { value:fourth_player_clicked, bind:bindFourthPlayerClicked, reset:resetFourthPlayerClicked } = useInput(false);

    const updateFirstPlayer = async (evt) => {
        evt.preventDefault();

        const data = {
            id: match.id,
            player1_id: evt.target.value,
        }

        try {
            let result = await tournament_api.updateGameByTournamentId(tournament_id, match.id, data);
            if (result === 204) {
                alert("Joueur mis à jour !");
                match.player1_id = evt.target.value;
                match.player1 = players.find(player => player.id === evt.target.value);
            } else {
                alert("Erreur lors de la mise à jour du joueur !");
            }
        }
        catch (e) {
            console.log(e);
        }
        return false;
    }
    const updateSecondPlayer = async (evt) => {
        evt.preventDefault();

        const data = {
            id: match.id,
            player2_id: evt.target.value,
        }

        try {
            let result = await tournament_api.updateGameByTournamentId(tournament_id, match.id, data);
            if (result === 204) {
                alert("Joueur mis à jour !");
                match.player2_id = evt.target.value;
                match.player2 = players.find(player => player.id === evt.target.value);
            } else {
                alert("Erreur lors de la mise à jour du joueur !");
            }
        }
        catch (e) {
            console.log(e);
        }
        return false;
    }
    const updateThirdPlayer = async (evt) => {
        evt.preventDefault();

        const data = {
            id: match.id,
            player3_id: evt.target.value,
        }

        try {
            let result = await tournament_api.updateGameByTournamentId(tournament_id, match.id, data);
            if (result === 204) {
                alert("Joueur mis à jour !");
                match.player3_id = evt.target.value;
                match.player3 = players.find(player => player.id === evt.target.value);
            } else {
                alert("Erreur lors de la mise à jour du joueur !");
            }
        }
        catch (e) {
            console.log(e);
        }
        return false;
    }
    const updateFourthPlayer = async (evt) => {
        evt.preventDefault();

        const data = {
            id: match.id,
            player4_id: evt.target.value,
        }

        try {
            let result = await tournament_api.updateGameByTournamentId(tournament_id, match.id, data);
            if (result === 204) {
                alert("Joueur mis à jour !");
                match.player4_id = evt.target.value;
                match.player4 = players.find(player => player.id === parseInt(evt.target.value));
            } else {
                alert("Erreur lors de la mise à jour du joueur !");
            }
        }
        catch (e) {
            console.log(e);
        }
        return false;
    }

    for (let i = 0; i < players.length; i++) {
        if (players[i].id === match.player1_id) {
            match.player1 = players[i];
        }
        if (players[i].id === match.player2_id) {
            match.player2 = players[i];
        }
        if (match.player3 !== null && players[i].id === match.player3_id) {
            match.player3 = players[i];
        }
        if (match.player4 !== null && players[i].id === match.player4_id) {
            match.player4 = players[i];
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        if (court === 0) {
            resetCourt();
            alert("Veuillez choisir un terrain !");
            return;
        }

        // check if court is available
        for (let i = 0; i < courts.length; i++) {
            if (courts[i].id === court - 1) {
                if (courts[i].game !== null) {
                    resetCourt();
                    alert("Terrain déjà occupé !");
                    return;
                }
            }
        }

        const data = {
            court: court,
            effective_time: new Date().toISOString().slice(0, 19).replace('T', ' '),
        }

        try {
            let result = await tournament_api.startGameByTournamentId(tournament_id, match.id, data);
            if (result === 204) {
                alert("Match commencé !");
            } else {
                alert("Erreur lors du démarrage du match !");
            }
        } catch (e) {
            console.log(e);
        }

        setTimeout(() => {
            window.location.reload();
        }, 2000);
        return false;
    }

    const all_players_as_options = players.map((player) => {
        return <option key={player.id} value={player.id}>{player.firstname} {player.lastname}</option>
    });

    return (
        <div>
            {(match.player1) && <div className="drop-shadow">
                <div className="flex justify-between bg-gray-50 rounded">
                    <div className="flex">
                        <div className="bg-flavigny text-white font-bold px-1 md:py-1 md:px-3 rounded-tl">
                            N° { match.custom_id + 1 }
                        </div>
                        <div className="bg-blue-300 px-1 md:py-1 md:px-3 rounded-br">
                        <span className="font-bold">
                            { match.time_start.substring(0, 5) }
                        </span>
                        </div>
                    </div>
                    <div className={match.court === 0 ? "bg-gray-300 text-white px-1 md:py-1 md:px-3 rounded-tr rounded-bl" : "bg-flavigny text-white px-1 md:py-1 md:px-3 rounded-tr rounded-bl"}>
                    <span>
                        T
                    </span>
                        <span className="font-bold">
                        { match.court }
                    </span>
                    </div>
                </div>
                <div className="bg-gray-50 rounded-b p-5">
                    <div className="flex justify-between items-center gap-4">
                        <div style={{fontWeight: match.ended && match.player1_win ? 'bold' : 'normal'}}>
                            { first_player_clicked && <div>
                                    <select onChange={(e) => {updateFirstPlayer(e); resetFirstPlayerClicked(false); }} value={match.player1_id}>
                                        {all_players_as_options}
                                    </select>
                                </div>
                            }
                            { !first_player_clicked && <div onClick={() => resetFirstPlayerClicked(true)} className="cursor-pointer">
                                { match.player1.firstname.charAt(0).toUpperCase() + match.player1.firstname.slice(1) } { match.player1.lastname.charAt(0).toUpperCase() + match.player1.lastname.slice(1) }
                                </div>
                            }
                            {(match.player3) && third_player_clicked && <div>
                                <select onChange={(e) => {updateThirdPlayer(e); resetThirdPlayerClicked(false); }} value={match.player3_id}>
                                    {all_players_as_options}
                                </select>
                            </div>}
                            {(match.player3) && !third_player_clicked && <div onClick={() => resetThirdPlayerClicked(true)} className="cursor-pointer">
                                { match.player3.firstname.charAt(0).toUpperCase() + match.player3.firstname.slice(1) } { match.player3.lastname.charAt(0).toUpperCase() + match.player3.lastname.slice(1) }
                            </div>}
                        </div>
                        {(!match.ended) && <div className="text-xl font-bold text-gray-400">
                            VS
                        </div>}
                        {(match.ended) && <div className="whitespace-nowrap text-sm md:text-xl font-bold text-green-400">
                            <div>{match.score1}</div>
                            <div>{match.score2}</div>
                            <div>{match.score3}</div>
                        </div>}
                        <div className="text-right" style={{fontWeight: match.ended && !match.player1_win ? 'bold' : 'normal'}}>
                            {(match.player2) && second_player_clicked && <div>
                                <select onChange={(e) => {updateSecondPlayer(e); resetSecondPlayerClicked(false); }} value={match.player2_id}>
                                    {all_players_as_options}
                                </select>
                            </div>}
                            {(match.player2) && !second_player_clicked && <div onClick={() => resetSecondPlayerClicked(true)} className="cursor-pointer">
                                { match.player2.firstname.charAt(0).toUpperCase() + match.player2.firstname.slice(1) } { match.player2.lastname.charAt(0).toUpperCase() + match.player2.lastname.slice(1) }
                            </div>}
                            {(match.player4) && fourth_player_clicked && <div>
                                <select onChange={(e) => {updateFourthPlayer(e); resetFourthPlayerClicked(false); }} value={match.player4_id}>
                                    {all_players_as_options}
                                </select>
                            </div>}
                            {(match.player4) && !fourth_player_clicked && <div onClick={() => resetFourthPlayerClicked(true)} className="cursor-pointer">
                                { match.player4.firstname.charAt(0).toUpperCase() + match.player4.firstname.slice(1) } { match.player4.lastname.charAt(0).toUpperCase() + match.player4.lastname.slice(1) }
                            </div>}
                        </div>
                    </div>
                </div>
            </div>}
            {(localStorage.getItem('username') !== null && match.court === 0 && !match.ended) &&
                <div className="bg-gray-100 p-2">
                    <form onSubmit={handleSubmit}>
                        <select {...bindCourt}>
                            <option value={0}>Choisir un terrain</option>
                            {Array.from(courts).map((court, index) => (
                                <option key={index} value={index + 1}>Terrain {index + 1}</option>
                            ))}
                        </select>
                        <button type="submit" className="font-bold px-3">
                            Valider
                        </button> (Cette action débutera le match)
                    </form>
                </div>
            }
        </div>
    );
}

export default MatchDisplay;