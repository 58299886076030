import {useInput} from "../../hooks/input-hook";
import React, {useState, useEffect, useRef} from 'react';
import {tournament_api} from "../../api/tournament_api";
import EventForm from "../form/EventForm";
import PopUp from "../global/PopUp";

function NumericKeypad({ onKeyPress, onClose }) {
    return (
        <div
            className="inset-0 bg-black bg-opacity-50 flex items-end justify-center"
            onClick={onClose}
        >
            <div
                className="bg-white w-full max-w-md"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="grid grid-cols-3">
                    <button
                        className="p-5 text-lg border"
                        onClick={() => onKeyPress('1')}
                    >
                        1
                    </button>
                    <button
                        className="p-5 text-lg border"
                        onClick={() => onKeyPress('2')}
                    >
                        2
                    </button>
                    <button
                        className="p-5 text-lg border"
                        onClick={() => onKeyPress('3')}
                    >
                        3
                    </button>
                    <button
                        className="p-5 text-lg border"
                        onClick={() => onKeyPress('4')}
                    >
                        4
                    </button>
                    <button
                        className="p-5 text-lg border"
                        onClick={() => onKeyPress('5')}
                    >
                        5
                    </button>
                    <button
                        className="p-5 text-lg border"
                        onClick={() => onKeyPress('6')}
                    >
                        6
                    </button>
                    <button
                        className="p-5 text-lg border"
                        onClick={() => onKeyPress('7')}
                    >
                        7
                    </button>
                    <button
                        className="p-5 text-lg border"
                        onClick={() => onKeyPress('8')}
                    >
                        8
                    </button>
                    <button
                        className="p-5 text-lg border"
                        onClick={() => onKeyPress('9')}
                    >
                        9
                    </button>
                    <button
                        className="p-5 text-lg border col-span-2"
                        onClick={() => onKeyPress('0')}
                    >
                        0
                    </button>
                    <button
                        className="p-5 text-lg border"
                        onClick={() => onKeyPress('backspace')}
                    >
                        ⌫
                    </button>
                </div>
            </div>
        </div>
    );
}


function CourtDisplay ({court, tournament_id, players}) {

    const { value:score1_j1, bind:bindScore1J1, reset:resetScore1 } = useInput(0);
    const { value:score1_j2, bind:bindScore1J2, reset:resetScore2 } = useInput(0);
    const { value:score2_j1, bind:bindScore2J1, reset:resetScore3 } = useInput(0);
    const { value:score2_j2, bind:bindScore2J2, reset:resetScore4 } = useInput(0);
    const { value:score3_j1, bind:bindScore3J1, reset:resetScore5 } = useInput(0);
    const { value:score3_j2, bind:bindScore3J2, reset:resetScore6 } = useInput(0);

    const [score_ok, setScoreOk] = useState(false);

    const input_fields_list = [
        "score1_j1",
        "score1_j2",
        "score2_j1",
        "score2_j2",
        "score3_j1",
        "score3_j2"
    ]
    const [activeInputField, setActiveInputField] = useState("score1_j1");
    const [showKeypad, setShowKeypad] = useState(true);


    const popUpRef = useRef();

    const [timeElapsed, setTimeElapsed] = useState(0);

    function isPlayer1Winner (score1_1, score1_2, score2_1, score2_2, score3_1, score3_2) {
        let winner = 0;

        if (score1_1 > score1_2) {
            winner++;
        } else {
            winner--;
        }

        if (score2_1 > score2_2) {
            winner++;
        } else if (score2_1 < score2_2) {
            winner--;
        }

        if (winner !== 0) {
            return winner;
        } else {
            if (score3_1 > score3_2) {
                winner++;
            } else if (score3_1 < score3_2) {
                winner--;
            }
            return winner;
        }
    }

    let effectiveTime;

    function calculateTimeElapsedEverySecond() {

        if (timeElapsed) {
            return
        }

        // get current time
        let now = new Date().getTime();
        // get time elapsed

        let distance = now - effectiveTime;

        // calculate minutes and seconds
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        // update time elapsed
        setTimeElapsed(minutes + ":" + seconds);

        setTimeout(calculateTimeElapsedEverySecond, 1000);
    }

    useEffect(() => {
        if (court.game !== null) {
            // effective time is a string like "10:00:00"
            const date = new Date();
            date.setHours(court.game.effective_time.split(":")[0], court.game.effective_time.split(":")[1], court.game.effective_time.split(":")[2], 0);
            effectiveTime = date.getTime();
            calculateTimeElapsedEverySecond();

            if (!court.game.player1) {
                // set player names
                for (let i = 0; i < players.length; i++) {
                    if (players[i].id === court.game.player1_id) {
                        court.game.player1 = players[i];
                    }
                    if (players[i].id === court.game.player2_id) {
                        court.game.player2 = players[i];
                    }
                    if (court.game.player3 !== null && players[i].id === court.game.player3_id) {
                        court.game.player3 = players[i];
                    }
                    if (court.game.player4 !== null && players[i].id === court.game.player4_id) {
                        court.game.player4 = players[i];
                    }
                }
            }
        }
    });

    // create a listener on "current_value" changes
    const handleChange = (id, value) => {
        if (value.toString().length >= 2) {
            console.log(value, id);
            if (id < input_fields_list.length) {
                setActiveInputField(input_fields_list[id]);
            } else if (id === input_fields_list.length) {
                setActiveInputField(null);
                setShowKeypad(false);
            }
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        if (score1_j1 < 0 || score1_j2 < 0 || score1_j1 === NaN || score1_j2 === NaN) {
            resetScore1();
            alert("Veuillez entrer un score n°1 valide !");
            return;
        }

        if (score2_j1 < 0 || score2_j2 < 0 || score2_j1 === NaN || score2_j2 === NaN) {
            resetScore2();
            alert("Veuillez entrer un score n°2 valide !");
            return;
        }

        if (score3_j1 !== 0 && score3_j2 !== 0) {
            if (score3_j1 < 0 || score3_j2 < 0 || score3_j1 === NaN || score3_j2 === NaN) {
                resetScore3();
                alert("Veuillez entrer un score n°3 valide !");
                return;
            }
        }

        const winner = isPlayer1Winner(parseInt(score1_j1), parseInt(score1_j2), parseInt(score2_j1), parseInt(score2_j2), parseInt(score3_j1), parseInt(score3_j2));
        // if (winner === 0) {
        //     alert("Veuillez entrer un score valide !");
        //     return;
        // }

        const scores = {
            score1: score1_j1 + " - " + score1_j2,
            score2: score2_j1 + " - " + score2_j2,
            score3: score3_j1 !== 0 && score3_j2 !== 0 ? score3_j1 + " - " + score3_j2 : null,
            player1_win: winner > 0
        };

        try {
            let result = await tournament_api.setGameResultByTournamentId(tournament_id, court.game.id, scores);
            if (result === 204) {
                resetScore1();
                resetScore2();
                resetScore3();
                resetScore4();
                resetScore5();
                resetScore6();
                setScoreOk(true);
            } else {
                alert("Erreur lors de la mise à jour du score !");
            }
        } catch (e) {
            console.log(e);
        }
        setTimeout(() => {
            window.location.reload();
        }, 2000);
        return false;
    };

    const handleKeypadPress = (key) => {
        if (key === 'backspace') {
            if (activeInputField === 'score1_j1') {
                resetScore1((prev) => prev.length <= 1 || prev === 0 ? "0" : prev.toString().slice(0, -1));
            } else if (activeInputField === 'score1_j2') {
                resetScore2((prev) => prev.length <= 1 || prev === 0 ? "0" : prev.toString().slice(0, -1));
            } else if (activeInputField === 'score2_j1') {
                resetScore3((prev) => prev.length <= 1 || prev === 0 ? "0" : prev.toString().slice(0, -1));
            } else if (activeInputField === 'score2_j2') {
                resetScore4((prev) => prev.length <= 1 || prev === 0 ? "0" : prev.toString().slice(0, -1));
            } else if (activeInputField === 'score3_j1') {
                resetScore5((prev) => prev.length <= 1 || prev === 0 ? "0" : prev.toString().slice(0, -1));
            } else if (activeInputField === 'score3_j2') {
                resetScore6((prev) => prev.length <= 1 || prev === 0 ? "0" : prev.toString().slice(0, -1));
            }
            // Ajoutez les autres champs ici
        } else {
            if (activeInputField === 'score1_j1') {
                resetScore1((prev) => {
                    const value = prev === 0 || prev === "0" ? key : prev + key;
                    handleChange(1, value);
                    return value;
                });
            } else if (activeInputField === 'score1_j2') {
                resetScore2((prev) => {
                    const value = prev === 0 || prev === "0" ? key : prev + key;
                    handleChange(2, value);
                    return value;
                });
            } else if (activeInputField === 'score2_j1') {
                resetScore3((prev) => {
                    const value = prev === 0 || prev === "0" ? key : prev + key;
                    handleChange(3, value);
                    return value;
                });
            } else if (activeInputField === 'score2_j2') {
                resetScore4((prev) => {
                    const value = prev === 0 || prev === "0" ? key : prev + key;
                    handleChange(4, value);
                    return value;
                });
            } else if (activeInputField === 'score3_j1') {
                resetScore5((prev) => {
                    const value = prev === 0 || prev === "0" ? key : prev + key;
                    handleChange(5, value);
                    return value;
                });
            } else if (activeInputField === 'score3_j2') {
                resetScore6((prev) => {
                    const value = prev === 0 || prev === "0" ? key : prev + key;
                    handleChange(6, value);
                    return value;
                });
            }
            // Ajoutez les autres champs ici
        }
    };

    return (
        <div className="grow min-w-sm">
            <PopUp ref={popUpRef} children={
                <div>
                    {court.game !== null && <div className={"flex flex-col justify-between"}>
                        {!score_ok && <div className="flex justify-between items-center gap-4">
                            <div>
                                {(court.game.player1) && <div>
                                    { court.game.player1.firstname.charAt(0).toUpperCase() + court.game.player1.firstname.slice(1) } { court.game.player1.lastname.charAt(0).toUpperCase() + court.game.player1.lastname.slice(1) }
                                </div>}
                                {(court.game.player3) && <div>
                                    { court.game.player3.firstname.charAt(0).toUpperCase() + court.game.player3.firstname.slice(1) } { court.game.player3.lastname.charAt(0).toUpperCase() + court.game.player3.lastname.slice(1) }
                                </div>}
                            </div>
                            <div>
                                {(court.game.player2) && <div>
                                    { court.game.player2.firstname.charAt(0).toUpperCase() + court.game.player2.firstname.slice(1) } { court.game.player2.lastname.charAt(0).toUpperCase() + court.game.player2.lastname.slice(1) }
                                </div>}
                                {(court.game.player4) && <div>
                                    { court.game.player4.firstname.charAt(0).toUpperCase() + court.game.player4.firstname.slice(1) } { court.game.player4.lastname.charAt(0).toUpperCase() + court.game.player4.lastname.slice(1) }
                                </div>}
                            </div>
                        </div>}
                        {score_ok && <div className="flex justify-between items-center gap-4">
                            <img src="https://media1.tenor.com/m/yqyHqTYHnBkAAAAC/thumbs-up-90s.gif" />
                        </div>}
                    </div>}
                    {!score_ok && <form className="space-y-1 p-2" onSubmit={handleSubmit} type="submit">
                        <div>
                            <label htmlFor="score1" className="text-sm font-medium text-gray-700">Score 1</label>
                            <div className="flex space-x-2">
                                <div>
                                    <input type="number" name="score1" id="score1"
                                            onFocus={() => {
                                                    setActiveInputField('score1_j1');
                                                    setShowKeypad(true);
                                            }}
                                           className={"w-full h-10 rounded-lg border border-gray-200 p-1 text-sm shadow-sm" + (activeInputField === 'score1_j1' ? " bg-green-50 border-green-500" : "")} {...bindScore1J1} />
                                </div>
                                <div className="text-3xl font-thin">-</div>
                                <div>
                                    <input type="number" name="score1" id="score1"
                                            onFocus={() => {
                                                setActiveInputField('score1_j2');
                                                setShowKeypad(true);
                                            }}
                                           className={"w-full h-10 rounded-lg border border-gray-200 p-1 text-sm shadow-sm" + (activeInputField === 'score1_j2' ? " bg-green-50 border-green-500" : "")} {...bindScore1J2} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="score2" className="text-sm font-medium text-gray-700">Score 2</label>
                            <div className="flex space-x-2">
                                <div>
                                    <input type="number" name="score2" id="score2"
                                            onFocus={() => {
                                                setActiveInputField('score2_j1');
                                                setShowKeypad(true);
                                           }}
                                           className={"w-full h-10 rounded-lg border border-gray-200 p-1 text-sm shadow-sm" + (activeInputField === 'score2_j1' ? " bg-green-50 border-green-500" : "")} {...bindScore2J1} />
                                </div>
                                <div className="text-3xl font-thin">-</div>
                                <div>
                                    <input type="number" name="score2" id="score2"
                                            onFocus={() => {
                                                setActiveInputField('score2_j2');
                                                setShowKeypad(true);
                                            }}
                                           className={"w-full h-10 rounded-lg border border-gray-200 p-1 text-sm shadow-sm" + (activeInputField === 'score2_j2' ? " bg-green-50 border-green-500" : "")} {...bindScore2J2} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="score3" className="text-sm font-medium text-gray-700">Score 3</label>
                            <div className="flex space-x-2">
                                <div>
                                    <input type="number" name="score3" id="score3"
                                            onFocus={() => {
                                                setActiveInputField('score3_j1');
                                                setShowKeypad(true);
                                            }}
                                           className={"w-full h-10 rounded-lg border border-gray-200 p-1 text-sm shadow-sm" + (activeInputField === 'score3_j1' ? " bg-green-50 border-green-500" : "")} {...bindScore3J1} />
                                </div>
                                <div className="text-3xl font-thin">-</div>
                                <div>
                                    <input type="number" name="score3" id="score3"
                                            onFocus={() => {
                                                setActiveInputField('score3_j2');
                                                setShowKeypad(true);
                                            }}
                                           className={"w-full h-10 rounded-lg border border-gray-200 p-1 text-sm shadow-sm" + (activeInputField === 'score3_j2' ? " bg-green-50 border-green-500" : "")} {...bindScore3J2} />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center pt-3">
                            <button type="submit" className="bg-green-500 w-full h-12 text-white rounded-lg px-3 py-1">Valider</button>
                        </div>
                    </form>}
                    {!score_ok && showKeypad && (
                    <NumericKeypad
                        onKeyPress={handleKeypadPress}
                        onClose={() => setShowKeypad(false)}
                    />
                )}
                </div>
            }/>
            <div onClick={() => {if (court.game) popUpRef.current.seePopup()}} className={"p-2 rounded-md " + (court.game ? timeElapsed > '30:00' ? "bg-orange-400" : "bg-green-400" : "bg-gray-400")}>
                <div className="text-center text-3xl font-bold text-white mb-3">
                    <h1>Terrain n°{court.id + 1}</h1>
                </div>
                {(court.game) && <div>
                    <div className="text-center text-xl">
                        <b>Match N°{ court.game.custom_id + 1 }</b> - { court.game.time_start.substring(0, 5) }
                    </div>
                    <div className="text-center text-white">
                        { timeElapsed }
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default CourtDisplay;