import { useState, useEffect } from "react";
import Card from "./Card";

const SHAPES = ["heart", "diamond", "club", "spade"];
const VALUES = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "jack", "queen", "king"];
const JOKER = "joker";

function Game({ p, c, onSubmit }) {

    const [pack, setPack] = useState([]);
    const [old_cards, setOldCards] = useState([]);
    const [isFlipped, setIsFlipped] = useState(true);
    const [isDisappear, setIsDisappear] = useState(false);
    const [canClick, setCanClick] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const [isNonJoker, setIsNonJoker] = useState(false);
    const [nbNonJoker, setNbNonJoker] = useState(0);
    const [nbNonJokerNextGame, setNbNonJokerNextGame] = useState(0);
    const [currentShape, setCurrentShape] = useState("heart");
    const [currentValue, setCurrentValue] = useState("1");

    const [old_nb_courts, setOldNbCourts] = useState(c);
    const [old_nb_players, setOldNbPlayers] = useState(p);
    const [nb_courts, setNbCourts] = useState(c);
    const [nb_players, setNbPlayers] = useState(p);

    const firstInit = (nb_courts, nb_players, nonJoker = false) => {
        setOldCards([]);
        setIsFlipped(true);
        setIsNonJoker(nonJoker && nbNonJokerNextGame > 0);
        let _pack = [];

        const max_players_by_turn = nb_courts * 4;
        const nb_possible_courts = max_players_by_turn > nb_players ? Math.floor(nb_players / 4) : nb_courts;
        const joker_by_turn = max_players_by_turn < nb_players ? nb_players - max_players_by_turn : nb_players % 4;
        setNbNonJoker(nbNonJokerNextGame);
        setNbNonJokerNextGame(joker_by_turn);

        for (let i = 0; i < nb_possible_courts; i++) {
            for (let j = 0; j < 4; j++) {
                _pack.push({ shape: SHAPES[j], value: VALUES[i] });
            }
        }

        for (let i = 0; i < joker_by_turn; i++) {
            _pack.push({ shape: JOKER, value: "black" });
        }

        setPack(_pack);
    };

    const anotherGame = () => {
        // eslint-disable-next-line no-restricted-globals
        if (isEnd || confirm('Attention, toutes les cartes n\'ont pas été tirées.')) {
            setIsDisappear( true);
            setTimeout(() => {
                setIsDisappear(false);
                setIsFlipped(true);
            }, 200);
            setTimeout(() => {
                firstInit(nb_courts, nb_players, true);
            }, 800);
        }
    }

    const leaveGame = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Souhaitez-vous quitter la partie ?')) {
            window.location.reload();
        }
    }

    const updatePack = (nb_c, nb_p) => {
        // eslint-disable-next-line no-restricted-globals
        if (old_nb_courts !== nb_c && confirm("Êtes-vous sûr de vouloir changer le nombre de terrains ?")) {
            setNbCourts(nb_c);
            setOldNbCourts(nb_c);
            firstInit(nb_c, nb_p);
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (old_nb_players !== nb_p && confirm("Êtes-vous sûr de vouloir changer le nombre de joueurs ?")) {
            setNbPlayers(nb_p);
            setOldNbPlayers(nb_p);
            firstInit(nb_c, nb_p);
        }
    }

    useEffect(() => {
        firstInit(nb_courts, nb_players);
    }, []);

    const drawCard = () => {
        if (!canClick) {
            return;
        }
        if (pack.length === 0) {
            setIsEnd(true);
            return;
        };

        if (isFlipped) {
            const card = _drawCard(isNonJoker);
            setCurrentShape(card.shape);
            setCurrentValue(card.value);
            setIsFlipped(isFlipped => !isFlipped);

            if (nbNonJoker >= 1) {
                let res = nbNonJoker - 1;
                setNbNonJoker(res);
                if (res === 0) {
                    setTimeout(() => {
                        setIsNonJoker(false);
                    }, 500);
                }
            }


            setOldCards(old_cards => [card, ...old_cards]);
            return;
        }
        setCanClick(false);
        setIsDisappear(isDisappear => !isDisappear);

        setTimeout(() => {
            setIsDisappear(isDisappear => !isDisappear);
            setIsFlipped(isFlipped => !isFlipped);
        }, 200);
        setTimeout(() => {
            setCanClick(true);
        }, 800);
    };

    const create_pack_without_jokers = () => {
        const _pack = [];
        for (let i = 0; i < pack.length; i++) {
            if (pack[i].shape !== JOKER) {
                _pack.push(pack[i]);
            }
        }
        return _pack;
    }

    const _drawCard = (isNonJoker) => {
        if (!isNonJoker) {
            const random_index = Math.floor(Math.random() * pack.length);
            setPack(pack.filter((c, i) => i !== random_index));
            return pack[random_index];
        }

        const pack_without_jokers = create_pack_without_jokers();
        const random_index = Math.floor(Math.random() * pack_without_jokers.length);
        const card = pack_without_jokers[random_index];
        setPack(pack.filter((c, i) => c.shape !== card.shape || c.value !== card.value));
        return card;
    }

    const undoCard = ({shape, value}) => {
        // add a notification/confirmation to undo the card
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Voulez-vous annuler cette carte ?')) {
            // remove the card from old_card
            setOldCards(old_cards => old_cards.filter(c => c.shape !== shape || c.value !== value));
            // add the card to pack
            setPack(pack => [...pack, {shape, value}]);
        }
    }

    return (
        <>
            <div>
                <div>
                    <div className={"relative z-50"}>
                        <div className={'absolute'}>
                            {pack.length > 0 && <Card shape="back" color={nbNonJoker >= 1 && isNonJoker ? "green" : "red"} className="back" />}
                        </div>
                        <div className={`card-container ${isFlipped ? 'flipped' : ''} ${!isFlipped && isDisappear ? 'disappear' : ''}`} onClick={drawCard}>
                            <Card shape="back" color={isNonJoker ? "green" : "red"} className="back" />
                            <Card shape={currentShape} value={currentValue} className="front" />
                        </div>
                    </div>
                </div>
                <div className={"absolute bottom-0 left-0 w-screen overflow-x-scroll z-30"}>
                    {/*  The list (slide) of last cards  */}

                    <div className={"flex ml-10"}>
                        {
                            old_cards.map((card, index) => {
                                return (
                                    <div className={"scale-50 -ml-20 -mb-14"} onClick={() => undoCard(card)}>
                                        <Card shape={card.shape} value={card.value} key={index} className="front" />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {isNonJoker && <div className={"absolute top-20 left-0 h-10 w-screen bg-gray-800/20 flex items-center p-2 z-30"}>
                    <div className={"text-white/70 text-sm"}>
                        [!] Le dos de couleur vert indique que la carte n'est pas un joker.
                    </div>
                </div>}
                <div className={"absolute top-0 left-0 h-20 w-screen bg-gray-100 flex items-center justify-between px-2 z-30"}>
                    <div className={"flex space-x-3"}>
                        <div>
                            <button
                                type="button"
                                className="text-2xl bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-4 px-4 rounded"
                                onClick={() => leaveGame()}
                            >
                                ❌
                            </button>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="text-2xl bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-4 px-4 rounded"
                                onClick={() => anotherGame()}
                            >
                                🔁
                            </button>
                        </div>
                    </div>
                    <div className={"space-x-10 hidden sm:flex"}>
                        <div className={""}>
                            <label htmlFor="">
                                <span className="text-gray-700">Nombre de terrains</span>
                            </label>
                            <div className="flex items-center justify-center">
                                <button
                                    type="button"
                                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
                                    onClick={() => {updatePack(nb_courts - 1, nb_players)}}
                                >
                                    -
                                </button>
                                <input
                                    className="shadow appearance-none w-16 text-center py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="number"
                                    value={nb_courts}
                                    onChange={(e) => {updatePack(Number(e.target.value), nb_players)}}
                                />
                                <button
                                    type="button"
                                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
                                    onClick={() => {updatePack(nb_courts + 1, nb_players)}}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label htmlFor="">
                                    <span className="text-gray-700">Nombre de joueurs</span>
                                </label>
                                <div className="flex items-center justify-center">
                                    <button
                                        type="button"
                                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
                                        onClick={() => {updatePack(nb_courts, nb_players - 1)}}
                                    >
                                        -
                                    </button>
                                    <input
                                        className="shadow appearance-none w-16 text-center py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="number"
                                        value={nb_players}
                                        onChange={(e) => {updatePack(nb_courts, Number(e.target.value))}}
                                    />
                                    <button
                                        type="button"
                                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
                                        onClick={() => {updatePack(nb_courts, nb_players + 1)}}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Game;
