import React, {useEffect, useState, useRef} from 'react';

import {schedules_api} from "../../api/schedules_api";
import SlidesHeader from '../global/SlidesHeader';

// Component (here it's a view)
function Header(props) {

    const [schedules, setSchedules] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const resp = await schedules_api.getSchedules();
            setSchedules(resp);
        }
        fetchData();
    }, []);

    const slides = [
        {
            content: (
                <div className={"bg-flavigny h-[90%] sm:h-full"}>
                    <div className={"mx-9"}>
                        <div className={"max-w-lg my-0 mx-auto relative flex h-48 sm:h-96"}>
                            <div className={"z-0 absolute right-0 bottom-0 w-full h-48 sm:h-[500px] bg-no-repeat bg-left-bottom bg-contain animate-slide-in"} style={{backgroundImage: "url('/img/gymnase.png')"}}></div>
                            <div className={"absolute bg-gradient-to-r from-flavigny to-green-600/0 w-1/2 h-[90%] sm:h-full"}></div>
                            <div className={"z-10"}>
                                <h1 className={"pt-10 sm:pt-32 text-5xl sm:text-7xl text-white font-bold font-outfit"}>ASFlavigny</h1>
                                <h2 className={"text-2xl sm:text-4xl text-white font-outfit font-extralight"}>Association sportive de Badminton de Flavigny</h2>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        // {
        //     content: (
        //         <div style={{backgroundColor: "#a52a4e"}}>
        //             <div className={"lg:mx-9"}>
        //                 <div className={"max-w-lg my-0 mx-auto relative flex h-48 sm:h-96"}>
        //                         <div className={"z-0 absolute right-0 bottom-0 w-full lg:-mb-10 h-48 sm:h-[500px] bg-no-repeat bg-left-bottom bg-cover"} style={{backgroundImage: "url('https://asfbad54.fr/content/photo/news/tournoi-octobre-rose-2024---infos-&-inscriptions/tournoi-octobre-rose-2024---infos-&-inscriptions.jpg')"}}></div>
        //                         <div className={"absolute z-10 right-0 bottom-0"}>
        //                         <a href='https://asfbad54.fr/actus/tournoi-octobre-rose-2024-infos-inscriptions'>
        //                             <div className={"sm:mb-32 mb-10 mr-10 p-4 bg-white sm:text-xl font-outfit"}>
        //                                 Cliquez-ici
        //                             </div>
        //                         </a>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     )
        // }
        {
            content: (
                <div style={{backgroundColor: "#FFF"}}>
                    <div className={"lg:mx-9"}>
                        <div className={"max-w-lg my-0 mx-auto relative flex h-48 sm:h-96"}>
                            <div className={"z-0 absolute right-0 bottom-0 w-full lg:-mb-10 h-48 sm:h-[500px] bg-no-repeat bg-center-bottom bg-contain"} style={{backgroundImage: "url('https://asfbad54.fr/content/photo/1/compoplume.jpeg')"}}></div>
                                <div className={"absolute z-10 right-0 bottom-0"}>
                                <a href='https://www.compoplume.fr/fr'>
                                    <div className={"sm:mb-32 mb-10 p-4 bg-flavigny text-white sm:text-xl font-outfit"}>
                                        Notre club est éco-responsable grâce à Compo Plume,
                                        <br/>qui recycle nos volants.
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    ];

    return (
        <SlidesHeader slides={slides}/>
    )
}

export default Header;
