import {Helmet} from "react-helmet";
import React, {useEffect, useState} from 'react';
import {tournament_api} from "../api/tournament_api";
import TournamentDisplay from "../components/tournaments/TournamentDisplay";
import TournamentCreateForm from "../components/tournaments/TournamentCreateForm";
import Card from "../components/cards/Card";

function Tournaments () {

    const [tournaments, setTournaments] = useState([]);
    const tournamentsDisplay = tournaments.reverse().map((tournament) =>
        <TournamentDisplay key={tournament.id} tournament = {tournament}/>
    );

    useEffect(() => {
        async function fetchData() {
            const resp = await tournament_api.getTournaments();
            setTournaments(resp);
        }
        fetchData();
    }, []);

    return (
        <div className="mt-6 mb-10">
            <Helmet>
                <meta property="og:title" content="Tournois du club | ASFlavigny" />
                <meta property="og:site_name" content="Tournois du club | ASFlavigny" />
                <meta property="og:description" content="Le badminton club de Flavigny (ASF), situé au sud de Nancy (près de Ludres et Richardménil), vous accueille dans une ambiance conviviale." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://asfbad54.fr/tournois" />
                <meta property="og:image" content="https://asfbad54.fr/img/logo-blue.png" />
        
                <meta charSet="utf-8" />
                <title>Tournois du club | ASFlavigny</title>
                <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                <script type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-GLK66L6C6D');
                    `}
                </script>
            </Helmet>

            <a href="/cartes?view"><div className="mb-5 h-24 bg-gradient-to-r from-green-700 via-green-800 to-green-900 overflow-hidden relative">
                <Card shape="back" value="" color="black" className="opacity-5 rotate-90 absolute -top-[75%] right-[45%] scale-[1000%]"></Card>
                <Card shape="back" value="" color="red" className="mt-2 rotate-3 absolute top-0"></Card>
                <Card shape="spade" value="2" color="black" className="ml-2 mt-8 rotate-6 absolute top-0"></Card>
                <Card shape="joker" value="black" color="black" className="ml-4 mt-16 rotate-12 absolute top-0"></Card>
                <div className="sm:hidden block absolute h-full w-full bg-gradient-to-r from-black/50 via-black/25 to-white/0">

                </div>
                <div className="absolute flex justify-end items-center h-full w-full">
                    <div className="text-3xl sm:text-4xl font-medium font-outfit text-white mr-10">
                        Bad'à'Cartes
                    </div>
                </div>
            </div></a>

            <div className="space-y-5">
                {tournamentsDisplay}
            </div>

            {(localStorage.getItem('username') !== null) && <div className="bg-red-200 w-full rounded p-2 mt-10">
                <h1 className="font-bold text-xl">Création d'un tournoi</h1>
                <div className="flex space-x-3">
                    <TournamentCreateForm />
                </div>
            </div>}
        </div>
    );
}

export default Tournaments;
