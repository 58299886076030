import React from 'react';

function Card({ shape, value = "", color = "black", className }) {
    let svg_url = "svg-cards.svg#" + shape;
    if (value !== "") {
        svg_url += "_" + value;
    }
    return (
        <div className={`card ${className}`}>
            <svg className="w-[172px] h-[248px]">
                <use href={svg_url} x="1" y="1" fill={color} />
            </svg>
        </div>
    );
}

export default Card;