import GameSettings from "../components/cards/GameSettings";
import {useState} from "react";
import Game from "../components/cards/Game";

function CardGame() {

    const [is_settings, setIsSettings] = useState(true);
    const [nb_players, setNbPlayers] = useState(20);
    const [nb_courts, setNbCourts] = useState(5);

    const handleSettings = (data) => {
        setIsSettings(false);
        setNbPlayers(data.nb_players);
        setNbCourts(data.nb_courts);
    };

    return (
        <>
            <div className={"absolute top-0 left-0 w-full h-full"}>
                <div className="h-[100dvh] flex items-center justify-center bg-gradient-to-r from-green-700 via-green-800 to-green-900">
                    {is_settings && <GameSettings c={nb_players} p={nb_courts} onSubmit={handleSettings}></GameSettings>}
                    {!is_settings && <Game c={nb_players} p={nb_courts} onSubmit={handleSettings}></Game>}
                </div>
            </div>

        </>
    )
}

export default CardGame;
