import {useInput} from "../../hooks/input-hook";
import React, {useState} from 'react';
import {tournament_api} from "../../api/tournament_api";

function TournamentForm ({tournament}) {

    const { value:firstname, bind:bindFirstname, reset:resetFirstname } = useInput('');
    const { value:lastname, bind:bindLastname, reset:resetLastname } = useInput('');
    const { value:email, bind:bindEmail, reset:resetEmail } = useInput('');
    const { value:password, bind:bindPassword } = useInput('');

    const [gender, setGender] = useState("male")
    const [level, setLevel] = useState(1)

    const onGenderChange = e => {
        setGender(e.target.value)
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        if (firstname === "") {
            alert("Veuillez renseigner un prénom !");
            return;
        }
        if (lastname === "") {
            alert("Veuillez renseigner un nom !");
            return;
        }
        if (email === "") {
            alert("Veuillez renseigner un email !");
            return;
        } else if (!email.includes("@") && !email.includes(".")) {
            alert("Veuillez renseigner un email valide !");
            return;
        }

        const player = {
            firstname: firstname.toLowerCase().normalize(),
            lastname: lastname.toLowerCase().normalize(),
            email: email,
            sex: gender !== 'male',
            level: level,
            tournament_id: tournament.id,
            password: password,
        }

        try {
            let result = await tournament_api.addPlayerToTournament(tournament.id, player);
            if (result.status === 204) {
                resetFirstname();
                resetLastname();
                setLevel(1);
                setGender("male");
                alert("Inscription réussie !");
            } else {
                alert(result.text);
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="w-full">
            <h2 className="text-xl">Formulaire d'inscription</h2>
            <form className="space-y-3 mt-2" onSubmit={handleSubmit} >
                <div>
                    <label htmlFor="firstname" className="text-sm font-medium text-gray-700">Prénom</label>
                    <input type="text" name="firstname" id="firstname" placeholder={"Prénom"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindFirstname} />
                </div>
                <div>
                    <label htmlFor="lastname" className="text-sm font-medium text-gray-700">Nom</label>
                    <input type="text" name="lastname" id="lastname" placeholder={"Nom"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindLastname}/>
                </div>
                <div>
                    <label htmlFor="email" className="text-sm font-medium text-gray-700">Email</label>
                    <input type="email" name="email" id="email" placeholder={"Email"}
                            className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindEmail}/>
                </div>
                {tournament.allowSex && <div>
                    <label className="text-sm font-medium text-gray-700">Genre</label>
                    <div className="flex w-full space-x-3">
                        <div className="flex grow">
                            <input
                                type="radio"
                                name="genderption"
                                value="male"
                                id="SelectMale"
                                className="peer hidden"
                                checked={gender === "male"}
                                onChange={onGenderChange}
                            />

                            <label
                                htmlFor="SelectMale"
                                className="flex w-full cursor-pointer items-center justify-center rounded-md border border-gray-100 bg-white px-3 py-2 text-gray-900 hover:border-gray-200 peer-checked:border-blue-500 peer-checked:bg-blue-500 peer-checked:text-white"
                            >
                                <p className="text-sm font-medium">Homme</p>
                            </label>
                        </div>

                        <div className="flex grow">
                            <input
                                type="radio"
                                name="genderption"
                                value={"female"}
                                id="SelectFemale"
                                className="peer hidden"
                                checked={gender === "female"}
                                onChange={onGenderChange}
                            />

                            <label
                                htmlFor="SelectFemale"
                                className="flex w-full cursor-pointer items-center justify-center rounded-md border border-gray-100 bg-white px-3 py-2 text-gray-900 hover:border-gray-200 peer-checked:border-blue-500 peer-checked:bg-blue-500 peer-checked:text-white"
                            >
                                <p className="text-sm font-medium">Femme</p>
                            </label>
                        </div>
                    </div>
                </div>}
                {tournament.allowLevel && <div>
                    <label htmlFor="level" className="text-sm font-medium text-gray-700">Niveau</label>
                    <select name="level" id="level" className="appearance-none w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" onChange={e => setLevel(e.target.value)}>
                        <option value={1}>Non classé</option>
                        <option value={2}>P12 - P11</option>
                        <option value={4}>P10 - D9</option>
                        <option value={6}>D8 - D7</option>
                        <option value={8}>R6 – R5</option>
                        <option value={10}>R4 – N3</option>
                        <option value={16}>N2 – N1</option>
                    </select>
                </div>}
                <div>
                    <label htmlFor="password" className="text-sm font-medium text-gray-700">Mot de passe du tournoi (donné par l'organisation)</label>
                    <input type="text" name="password" id="password" placeholder={"Mot de passe du tournoi"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindPassword}/>
                </div>

                <button
                    className="w-full inline-block rounded border border-blue-500 bg-blue-500 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-indigo-500"
                >
                    Envoyer l'inscription
                </button>
                <p className="text-xs text-center">
                    En vous inscrivant, vous autorisez l'organisation à utiliser vos données personnelles pour l'organisation du tournoi.
                </p>
            </form>
        </div>
    );
}

export default TournamentForm;