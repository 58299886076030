import {useParams} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {tournament_api} from "../api/tournament_api";
import {Helmet} from "react-helmet";
import CourtDisplay from "../components/tournaments/CourtDisplay";
import CourtDisplayScoring from "../components/tournaments/CourtDisplayScoring";


function Tournament () {

    const { tournament } = useParams();

    const [currentTournament, setTournament] = useState({});
    const [currentTournamentStatus, setTournamentStatus] = useState(1);
    const [courts, setCourts] = useState([]);
    const [matches, setMatches] = useState([]);
    const [players, setPlayers] = useState([]);
    const [seeScores, setSeeScores] = useState(false);
    const [seeUpdateForm, setSeeUpdateForm] = useState(false);
    const [seePlayers, setSeePlayers] = useState(false);
    const courtsDisplay = courts.map((court) =>
        <CourtDisplayScoring key={court.id} court = {court} tournament_id={tournament} players={players}/>
    );

    function checkTournamentStatus(date, time_start, time_end) {
        // if date is in the future return 1
        // if date is in the past return -1
        // else return 0

        let today = new Date();

        let tournament_date = new Date(date);
        tournament_date.setHours(time_start.split(":")[0], time_start.split(":")[1], time_start.split(":")[2], 0);

        if (tournament_date > today) {
            return 1;
        }
        else {
            // check if current time is between time_start and time_end (time string format: "10:00:00")
            let tournament_time_end = new Date(date);
            tournament_time_end.setHours(time_end.split(":")[0], time_end.split(":")[1], time_end.split(":")[2], 0);
            if (today >= tournament_date && today <= tournament_time_end) {
                return 0;
            }
            // if at least one match is not ended, return 0
            else if (matches.filter((match) => !match.ended).length > 0) {
                return 0;
            }
            else {
                return -1;
            }
        }
    }

    async function generateTournamentGames() {
        
        if (currentTournamentStatus === 0) {
            alert("Le tournoi est en cours, vous ne pouvez pas (re)générer les matchs !");
            return;
        } else if (currentTournamentStatus === -1) {
            alert("Le tournoi est terminé, vous ne pouvez pas (re)générer les matchs !");
            return;
        }

        if (!window.confirm("Êtes-vous sûr de vouloir (re)générer les matchs ?")) {
            return;
        }

        try {
            const resp = await tournament_api.generateTournamentGamesByTournamentId(tournament);
            if (resp === 204) {
                alert("Les matchs ont été (re)générés !");
                window.location.reload();
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        async function fetchData() {
            const resp = await tournament_api.getTournamentById(tournament);
            setTournament(resp);
            const respC = await tournament_api.getCourtsByTournamentId(tournament);
            setCourts(respC);
            const respM = await tournament_api.getMatchesByTournamentId(tournament);
            setMatches(respM);
            const respP = await tournament_api.getPlayersByTournamentId(tournament);
            setPlayers(respP);

            const status = checkTournamentStatus(resp.date, resp.time_start, resp.time_end);
            setTournamentStatus(status);
            if (status === -1) {
                setSeeScores(true);
            }

            if (status === 0) {
                // return total delay of tournament in minutes
                // for each match, get delay
                let delay = 0;
                respM.forEach((respM) => {

                    const time_start = new Date();
                    time_start.setHours(respM.time_start.split(":")[0], respM.time_start.split(":")[1], respM.time_start.split(":")[2], 0);

                    let starded = false;
                    const time_effective = new Date();
                    if (respM.effective_time) {
                        starded = true;
                        time_effective.setHours(parseInt(respM.effective_time.split(":")[0]) + 2, respM.effective_time.split(":")[1], respM.effective_time.split(":")[2], 0);
                    }

                    // if match is started and not ended, add delay
                    if (starded && !respM.ended) {
                        delay += time_start - time_effective;
                    } else if (!starded && time_start < new Date()) {
                        delay += time_start - new Date();
                    }
                });

                setTimeout(async () => {
                    await fetchData()
                }, 10000);
            }
        }
        fetchData();
    }, []);

    return (

        <div>
            <Helmet>
                <meta property="og:title" content={currentTournament.name + " | ASFlavigny"} />
                <meta property="og:site_name" content={currentTournament.name + " | ASFlavigny"} />
                <meta property="og:description" content="Le badminton club de Flavigny (ASF), situé au sud de Nancy (près de Ludres et Richardménil), vous accueille dans une ambiance conviviale." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://asfbad54.fr" />
                <meta property="og:image" content="https://asfbad54.fr/img/logo-blue.png" />
        
                <meta charSet="utf-8" />
                <title>{currentTournament.name + " | ASFlavigny"}</title>
                {currentTournament.name &&
                    <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                }
                {currentTournament.name &&
                    <script type="text/javascript">
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'G-GLK66L6C6D');
                        `}
                    </script>
                }
            </Helmet>

            {(currentTournamentStatus !== 1) &&
                <div className="space-y-7">
                    {(currentTournamentStatus !== -1 && !seeScores) && <div>
                        <div className="space-y-3">
                            { courtsDisplay }
                        </div>
                    </div>}
                </div>
            }
        </div>
    )
}

export default Tournament;
